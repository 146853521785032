<template>
  <div class="create-scheduling">
    <CForm
      ref="createMember"
      novalidate
      class="create-form"
    >
      <div class="form-content content">
        <h3 class="mb-3 bread-content">{{ texts.createPage.schedulingStep.title }}</h3>
        <!--    FROM    -->
        <ClFormRow
          :label="texts.createPage.schedulingStep.startLabel"
          xl-content="4"
          :toolTipText="descriptions.achievementAvailableFrom"
        >
          <template #content>
            <DatePicker
              :dateProp="startDate"
              @input="setStartDate"
              :placeholder="texts.createPage.schedulingStep.startPlaceholder"
              :isValid="fromValidate !== false"
              class="schedule-startDate"
              :disabled="isFieldDisabled"
              :disabledDate="disabledStartDate"
            />
          </template>
        </ClFormRow>
        <!--     TILL   -->
        <ClFormRow
          :label="texts.createPage.schedulingStep.endLabel"
          xl-content="4"
          :toolTipText="descriptions.till"
        >
          <template #content>
            <DatePicker
              :fieldName="'endDate'"
              class="end-date"
              :dateProp="endDate"
              @input="setEndDate"
              :placeholder="texts.createPage.schedulingStep.endPlaceholder"
              :disabled="isFieldDisabled"
              :disabledDate="disabledEndDate"
              :disabled-time="disabledEndDateTime"
            />
            <div v-if="invalidEndDate" class="invalid-end-date">
              {{ endDateErrorMessage }}
            </div>
          </template>
        </ClFormRow>
        <!-- Schedule Type   -->
        <ClFormRow
          :label="texts.createPage.schedulingStep.scheduleTypeLabel"
          xl-content="4"
          :toolTipText="descriptions.membersCanAchieveOn"
        >
          <template #content>
            <ClSelect
              name="scheduleType"
              class="text-capitalize"
              :options="occurrencesLimitOption"
              :placeholder="texts.createPage.schedulingStep.scheduleTypePlaceholder"
              :valueProp="formData.scheduleType"
              size="xxl"
              required
              :selectLabel="selectLabel"
              :invalidFeedback="texts.invalidFeedback"
              :is-valid="scheduleTypeValidate"
              :disabled="isFieldDisabled"
              @checkValue="scheduleTypeUpdate"
            />
          </template>
        </ClFormRow>
        <!--    When    -->
        <ClFormRow
          :label="texts.createPage.schedulingStep.whenLabel"
          xl-content="5"
          v-if="isEveryVisible"
        >
          <template #content>
            <multiselect
              :class="selectErrorClass"
              v-model="everyChecked"
              :options="every"
              label="label"
              track-by="value"
              :multiple="true"
              :disabled="isFieldDisabled"
              :selectLabel="selectLabel"
              :tagPlaceholder="tagPlaceholder"
              :preserve-search="true"
              :hide-selected="true"
              class="custom-multi-select zq--multiselect"
            >
              <template #caret>
                <div></div>
              </template>
            </multiselect>
            <img
              src="../../../../assets/icons/search.svg"
              width="16"
              height="16"
              alt="search"
              class="zq--multiselect-search-icon"
            >
          </template>
        </ClFormRow>
        <!--  Schedule Occurrences Limit      -->
        <ClFormRow
          :label="texts.createPage.schedulingStep.occurrencesLabel"
          v-if="isEveryVisible || formData.scheduleType === 'Repeatedly'"
        >
          <template #content>
            <CInput
              type="number"
              :placeholder="texts.createPage.schedulingStep.occurrencesPlaceholder"
              v-model.number="formData.scheduleOccurrencesLimit"
              add-input-classes="col-sm-12"
              min="1"
              step="1"
              :disabled="isFieldDisabled"
              @update:value="handleUpdateLimit"
              :invalidFeedback="texts.invalidFeedback"
              :isValid="scheduleOccurrencesLimitValidate"
            />
          </template>
        </ClFormRow>
        <div v-if="showMaxNumberOfIssues">
          <SwitchRow
            :label="texts.createPage.schedulingStep.maxNumberOfIssues"
            :toolTipText="descriptions.autoStart"
            :valueProp="isMaxNumberOfIssues"
            :disabled="isFieldDisabled"
            @swipeHandler="swipeMaxNumberOfIssuesHandler"
            @updateHandler="maxNumberOfIssuesUpdate"
          />
          <ClFormRow v-if="isMaxNumberOfIssues">
            <template #content>
              <CInput
                name="maxNumberOfIssues"
                type="number"
                v-model.number="formData.maxNumberOfIssues"
                add-input-classes="col-sm-12"
                min="1"
                step="1"
                :disabled="isFieldDisabled"
                :invalidFeedback="texts.tooLargeValue"
              />
            </template>
          </ClFormRow>
        </div>
      </div>
    </CForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { range } from 'lodash';
import DatePicker from '@/shared/components/DatePicker';
import SwitchRow from '@/shared/components/SwitchRow';
import { isAchFieldDisabled } from '@/utils/validationUtils';
import ClFormRow from '@/shared/components/formComponents/ClFormRow';
import ClSelect from '@/shared/components/formComponents/ClSelect';
import { formConfig } from '@/config';
import { achievements } from '@/config/descriptions/achievements';
import { notifications } from '@/config/descriptions/notifications.json';
import { achievementsTexts } from '@/config/pageTexts/achievements.json';
import { notificationsTexts } from '@/config/pageTexts/notifications.json';

export default {
  name: 'CreateScheduling',
  components: {
    DatePicker,
    ClFormRow,
    ClSelect,
    SwitchRow,
  },
  props: {
    schedulingData: Object,
    name: String,
    isFrom: Boolean,
    isScheduleType: Boolean,
    isEvery: Boolean,
    isLimit: Boolean,
    achievementType: String,
    isMessagingPage: { type: Boolean, default: false }
  },
  data() {
    return {
      texts: this.isMessagingPage ? {
        ...notificationsTexts
      } : {...achievementsTexts},
      endDateErrorMessage: achievements.create.scheduling.endDateErrorMessage,
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      startDate: '',
      endDate: null,
      everyDay: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      everyMonth: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      occurrencesLimitOption: ['Once', 'Repeatedly', 'Daily', 'Weekly', 'Monthly'],
      everyChecked: [],
      formData: {
        scheduleType: '',
        scheduleOccurrencesLimit: 1,
        maxNumberOfIssues: null,
        every: [],
        startDate: '',
        onlyAggregateOnActiveDays: false
      },
      fromValidate: null,
      scheduleTypeValidate: null,
      everyValidate: null,
      scheduleOccurrencesLimitValidate: null,
      descriptions: {
        achievementAvailableFrom: this.isMessagingPage
          ? notifications.create.scheduling.availableFrom
          :  achievements.list.create.scheduling.achievementAvailableFrom,
        till: this.isMessagingPage
          ? notifications.create.scheduling.till
          : achievements.list.create.scheduling.till,
        membersCanAchieveOn: this.isMessagingPage
          ? notifications.create.scheduling.scheduleType
          : achievements.list.create.scheduling.membersCanAchieveOn
      },
      showMaxNumberOfIssues: false,
      isMaxNumberOfIssues: false,
      invalidEndDate: false
    }
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    every() {
      if (this.formData.scheduleType === 'Daily') return this.arrayToEvery(this.everyDay);
      if (this.formData.scheduleType === 'Monthly') return this.arrayToEvery(this.everyMonth);
      if (this.formData.scheduleType === 'Weekly') return this.weeklyToEvery(range(1, 53));

      return null;
    },
    isEveryVisible() {
      return this.formData.scheduleType === 'Daily'
        || this.formData.scheduleType === 'Monthly'
        || this.formData.scheduleType === 'Weekly';
    },
    isFieldDisabled() {
      return isAchFieldDisabled(this.achievementType)
    },
    selectErrorClass() {
      const className = this.theme === 'main' ? 'error-validation--multiselect' : 'error-validation';

      return {
        [className]: this.everyValidate
      }
    }
  },
  methods: {
    initialize() {
      this.formData = this.schedulingData;

      if (this.schedulingData.endDate) {
        const endDate =  new Date(this.schedulingData.endDate);
        this.endDate = new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000);
      }

      if (this.schedulingData.startDate) {
        const startDate = new Date(this.schedulingData.startDate);
        this.startDate = new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000);
      }

      if (this.schedulingData.maxNumberOfIssues) this.isMaxNumberOfIssues = true;
      this.everyChecked = this.schedulingData.every.map((item) => {
        return this.every[item - 1]
      });
    },
    handleUpdateLimit(val) {
      if (val > 2147483647) this.formData.scheduleOccurrencesLimit = 2147483647;
      if (!val || val < 1) {
        this.formData.scheduleOccurrencesLimit = 1;
      } else {
        this.formData.scheduleOccurrencesLimit = parseInt(val)
      }
    },
    setStartDate(date) {
      const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

      this.formData.startDate = date ? isoDate : "";
      this.startDate = date;
    },
    setEndDate(date) {
      if (!date) return;

      this.invalidEndDate = false;
      let invalidFieldElement = document.getElementsByName('endDate')[0];
      let invalidElement = invalidFieldElement;
      if (!invalidFieldElement.classList.contains('zq--form-row')) {
        invalidElement = invalidFieldElement.parentNode;
      }
      invalidElement.classList.remove('zq-invalid');

      const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

      this.formData.endDate = date ? isoDate : null;
      this.endDate = date;
    },
    arrayToEvery(array) {
      return array.reduce((acc, item, index) => {
        return [
          ...acc,
          {
            value: index + 1,
            label: item
          }
        ]
      }, []);
    },
    weeklyToEvery(array) {
      return array.reduce((acc, item) => {
        return [
          ...acc,
          {
            value: item,
            label: `week ${item}`
          }
        ]
      }, []);
    },
    scheduleTypeUpdate({value}) {
      if (value === 'Once') {
        this.formData.scheduleOccurrencesLimit = 1;
      }

      this.formData.scheduleType = value;
      if (!['Daily', 'Weekly', 'Monthly'].includes(value)) {
        this.everyChecked = [];
      } else {
        switch (value) {
          case 'Daily':
            if (this.everyChecked.length) {
              if (!this.everyDay.includes(this.everyChecked[0].label)) {
                this.everyChecked = [];
                this.formData.scheduleOccurrencesLimit = 1;
              }
            }
            return;
          case 'Weekly':
            if (this.everyChecked.length) {
              if (!this.everyChecked[0].label.includes('week')) {
                this.everyChecked = [];
                this.formData.scheduleOccurrencesLimit = 1;
              }
            }
            return;
          case 'Monthly':
            if (this.everyChecked.length) {
              if (!this.everyMonth.includes(this.everyChecked[0].label)) {
                this.everyChecked = [];
                this.formData.scheduleOccurrencesLimit = 1;
              }
            }
            return;
        }
      }

      this.$emit('resetValidate');
    },
    everyDailyCheck(index) {
      if (this.everyEveryChecked.includes(index)) {
        this.everyEveryChecked = this.everyEveryChecked.filter(val => val !== index);
      } else {
        this.everyEveryChecked.push(Number(index))
      }
    },
    disabledStartDate(d) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return d < new Date(new Date().setHours(0, 0, 0, 0));
    },
    disabledEndDate(d) {
      const date = this.startDate ? new Date(this.startDate) : new Date();
      date.setHours(0, 0, 0, 0);
      return d < date;
    },
    disabledEndDateTime(d) {
      const hours = this.startDate.getHours();
      const minutes = this.startDate.getMinutes();
      const seconds = this.startDate.getSeconds();

      return d < new Date(new Date(this.startDate).setHours(hours, minutes, seconds));
    },
    swipeMaxNumberOfIssuesHandler(direction) {
      this.isMaxNumberOfIssues = direction === 'right';
    },
    maxNumberOfIssuesUpdate(val) {
      this.isMaxNumberOfIssues = val;
    },
    setInvalidEndDate() {
      this.invalidEndDate = true;
    },
  },
  watch: {
    isLimit(val) {
      this.scheduleOccurrencesLimitValidate = val;
    },
    isFrom(val) {
      this.fromValidate = val
    },
    isScheduleType(val) {
      this.scheduleTypeValidate = val;
    },
    isEvery(val) {
      this.everyValidate = val;
    },
    formData: {
      deep: true,
      handler: function (val) {
        if (val.scheduleType === 'Daily' && this.everyChecked.length) {
          val.onlyAggregateOnActiveDays = true;
        }

        if (val.startDate) {
          this.fromValidate = true
        } else {
          this.$emit('resetValidate');
        }

        if (val.scheduleType) this.scheduleTypeValidate = true;
        if (val.scheduleOccurrencesLimit) this.scheduleOccurrencesLimitValidate = true;
        if (val.every.length) this.everyValidate = false;

        if (val.maxNumberOfIssues) {
          let element = document.getElementsByName('maxNumberOfIssues')[0];
          let invalidElement = element;
          if (element && !element.classList.contains('zq--form-row')) {
            invalidElement = element.parentNode;
          }

          if (invalidElement && invalidElement.classList.contains('zq-invalid')) {
            invalidElement.classList.remove('zq-invalid');
          }
        }

        this.$emit('updateSchedulingData', val);
      },
    },
    everyValidate(val) {
      if (!val) this.$emit('resetValidate');
    },
    everyChecked: {
      deep: true,
      handler: function (val) {
        this.formData.every = val.reduce((acc, item) => {
          return [
            ...acc,
            item.value
          ]
        }, []);
      }
    }
  },
  destroyed() {
    this.$emit('resetValidate');
  }
}
</script>

<style lang="scss">
@import "~@coreui/coreui/scss/coreui";

.create-scheduling {
  height: 100%;
  .create-form {
    height: 100%;
  }
  .form-content {
    background: var(--zq-sub-bg);
    height: 100%;

    .zq--data-picker.mx-datepicker {
      width: 100%;
      min-width: 100%;
    }

    .zq--form-row {
      .zq--form-row--content {
        position: relative;
        .zq--data-picker--wrap.end-date.zq-invalid{
          border: solid 1px var(--zq-warn) !important;
          border-radius: 12px;
        }
        .invalid-end-date {
          position: absolute;
          bottom: -17px;
          left: 10px;
          color: var(--zq-warn);
        }
      }
    }
  }
  .every {
    display: flex;
    flex-wrap: wrap;
    .every-date-el {
      background: #f1f1f1 none repeat scroll 0 0;
      border: 1px solid #f1f1f1;
      cursor: pointer;
      float: left;
      font-size: 16px;
      line-height: 30px;
      margin: 0 2px 2px 0;
      min-width: 45px;
      padding: 0 3px;
      text-align: center;
    }
    .active {
      background: #e4e3e3 none repeat scroll 0 0;
      border: 1px solid #aaaaaa;
      position: relative;
      &:after {
        content: "+";
        font-size: 11px;
        line-height: 7px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  .zq-invalid {
    .invalid-feedback {
      display: block;
    }
  }
}
</style>
